/* Small Shit */
.IntroContainer {
    display: grid;
    justify-content: center;
    align-content: center;
    height: 375px;
    grid-template-columns: repeat(1, 2fr); /* Two columns, one row */
    width: 85%;
    margin-bottom: 50px;
  }

  .PictureContainer {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      display: none;
      justify-content: center;
      
  }

  .PicObj {
      width: 50%;
      height: 75%;
      margin: auto 0;
      border-radius: 50%; /*Creates circle shape*/
      border: 3px solid #ccc; /*Added a basic border for visual "niceness"*/
      object-fit: cover; /* Ensures the image fills the circle while maintaining its aspect ratio */
  }

  .IntroContent {
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      justify-content: left; 
      align-content: left; 
      gap: 20px;
      margin: 10 0px;
      width: 100%;
      height: 375px;
      
  }

  .ContactAndLinks {
      display: grid;          /* Use CSS Grid */
      grid-template-columns: repeat(2, 2fr);
      list-style-type: none;  /* Remove default list styling */
      padding: 0;             /* Remove default padding */
      margin: 0;              /* Remove default margin */
      row-gap: 10px;              /* Optional: add space between items */
  } 

  .ContactAndLinks li {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .ContactBtn {
    padding: 10px 20px;
    background-color: #001D3F;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 20px;
    border: 3px solid #ccc; /*Added a basic border for visual "niceness"*/
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + .25vw); /* Ensure consistent font size */
    text-align: center;
}
  .ContactBtn:hover {
      background-color: #0056b3;
  }

  .ContactObj {
      color: #ccc;
      text-align: center;
      margin-top: 5px;
  }

/* Adjust layout for DESKTOP Devices */
@media (min-width: 900px) {
    .IntroContainer {
      display: grid;
      justify-content: center;
      align-content: center;
      height: 375px; /* Optional: Set a height for the intro section */
      grid-template-columns: repeat(2, 1fr); /* Two columns, one row */
      width: 950px;
      margin: 25px;
    }

    .PictureContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .PicObj {
        width: 50%;
        height: 75%;
        margin: auto 0;
        border-radius: 50%; /*Creates circle shape*/
        border: 3px solid #ccc; /*Added a basic border for visual "niceness"*/
        object-fit: cover; /* Ensures the image fills the circle while maintaining its aspect ratio */
    }

    .IntroContent {
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        justify-content: left; 
        align-content: left; 
        gap: 20px;
        width: 100%;
        height: 100%;
    }

    .ContactAndLinks {
        display: grid;          /* Use CSS Grid */
        grid-template-columns: repeat(4, 1fr);
        list-style-type: none;  /* Remove default list styling */
        padding: 0;             /* Remove default padding */
        margin: 0;              /* Remove default margin */
        column-gap: 10px;              /* Optional: add space between items */
    } 

    .ContactAndLinks li {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ContactBtn {
        padding: 10px 20px;
        /* background-color: #001D3F; */ 
        color: white;
        text-decoration: none;
        border: none;
        border-radius: 20px;
        border: 3px solid #ccc; /*Added a basic border for visual "niceness"*/
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + .25vw); /* Ensure consistent font size */
        text-align: center;
    }
    .ContactBtn:hover {
        background-color: #0056b3;
    }

    .ContactObj {
        color: #ccc;
        text-align: center;

    }
}





