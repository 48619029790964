.TransitionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #4178B8, #46BDA8);
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 20px;
    margin-bottom: 10px;

  }
  
  .TransitionHeader {
    font-size: 1.5em; 
    color: #fff; /* Optional: Change text color */
    margin: 0;
    text-align: center;
  }

  @media (min-width: 900px) {
    .TransitionContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(135deg, #4178B8, #46BDA8);
      border-radius: 10px;
      padding: 10px 20px;
      margin-bottom: 20px;
    }
    
    .TransitionHeader {
      font-size: 1.5em; 
      color: #fff; /* Optional: Change text color */
      margin: 0;
      text-align: center;
    }
  }