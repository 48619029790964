/* ACTUALLY Adjust layout for big shit 5.24.24 */


.ProjectItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Optional: Adjust spacing between project items */
  }

.ImageContainer {
  width: 450px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* When filling the space, img overflow is hidden */
  margin: 20px; /* Ensure forced space between img. and desc. */
  border-radius: 20px; /* Standard rounded edges */
  border: 3px solid #ccc; /*Added a basic border for visual "niceness"*/
}
  
.DescriptionContainer {
  width: 450px;
  height: 300px;
  display: grid;
  background-color: #001D3F; /* Example background color */
  font-size: 12px; /* Base line comfortable font size */
  line-height: 1.4; /* Increased line height for better readability */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Display (...) for potential overflow text */
  margin: 20px; /* Ensure forced space between img. and desc. */
  border-radius: 20px; /* Standard rounded edges */
  border: 3px solid #ccc; /*Added a basic border for visual "niceness"*/
  justify-content: center;
  align-items: center;
}

.ProjImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ProjTitle {
  text-align: center;
  margin: 0;
}

.ProjDesc {
  /* Makes the text work more like a text box */
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content:center;
  text-align: center;
  margin: 0;
}
.Links {
  text-align: center;
  display: flex;
  justify-content: space-around; /* Distribute space between links */
  padding: 0 40px; /* Add padding to ensure links don't reach the edges */
}
.Links a {
  color: #FFD700; /* Gold color for contrast */
  text-decoration: none; /* Remove underline */
}

.Links a:visited {
  color: #FFD700; /* Ensure visited links stay the same color */
}

.Links a:hover {
  color: #FFA500; /* Slightly different color on hover for interactivity */
}

.Links a:active {
  color: #FFD700; /* Ensure clicked links stay the same color */
}

/* ACTUALLY Adjust layout for mobile devices 5.23.24 */
@media (max-width: 768px) {
  .ProjectItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  .ImageContainer,
  .DescriptionContainer {
    max-width: 375px;
    font-size: 2.8vw;
    
  }

  .ProjImg {
    width: 100%;
    border-radius: 20px; /* Standard rounded edges */
  }
}
 