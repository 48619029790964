html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
  background: rgba(2,0,36,1)
}

.App {
  background: rgba(2,0,36,1)
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(181deg, rgba(15, 25, 45, 1) 0%, rgba(40, 55, 80, 1) 48%, rgba(75, 95, 120, 1) 100%);

  /* background-color: #282c34; */
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  box-sizing: border-box; /* Include padding in element's total width/height */
}

/* Adjust layout for mobile devices */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin); /* Adjust font size for smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
  }
}

.App-link {
  color: #61dafb;
}

.EndCredits {
  text-align: center;
  justify-content: center;
  color: #ccc; /* Optional: Change text color */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*
display: none;: This completely removes the element from the document layout, 
and it will not take up any space on the page.

visibility: hidden;: This hides the element but it will still take up space in 
the document layout.

opacity: 0;: This makes the element fully transparent, effectively hiding it, 
but it will still take up space and can interact with mouse events unless
pointer-events is set to none.

*/