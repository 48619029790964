.ProjectsContainer {
    width: 100%; /* Adjusted to 100% */
    max-width: 900px;
    margin: 0 auto;
    padding: 0 20px; /* Optional: Add padding */
    align-items: center;
}

@media (max-width: 768px) {
    .ProjectsContainer {
        width: 90%;
        align-items: center;
    }
}