.ResumeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Ensure centering */
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box; /* Include padding in element's total width/height */
}

.ResumeImg {
    width: 100%;
    height: auto;
    border-radius: 40px;
}

.DownloadButton {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #001D3F;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center; /* Ensure text is centered */
}
  
.DownloadButton:hover {
    background-color: #0056b3;
}

/* Adjust layout for mobile devices */
@media (max-width: 768px) {
  .ResumeContainer {
    width: 100%;
    padding: 10px;
  }

  .ResumeImage {
    width: 100%;
  }

  .DownloadButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}

